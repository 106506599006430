import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hollow Rocks 3x:30 & Superman Extensions 3×20`}</p>
    <p>{`then,`}</p>
    <p>{`Partial Clean Grip Deadlifts 5×2\\@90% Deadlift 1RM (Plates elevated 4″)`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`500M Row `}<em parentName="p">{`(modifiy to 400M Run or 800M Bike)`}</em></p>
    <p>{`20-Bar Facing Burpees`}</p>
    <p>{`10-Deadlifts (225/155)(275/185)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      